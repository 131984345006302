import React from "react"

import Layout from "../../components/Layout"
import { graphql } from "gatsby"
import Card from "../../components/ui/Card"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../../components/Seo"

export default function Page({ data }) {
  const articles = data.allStrapiPress.nodes

  return (
    <Layout>
      <Seo title="Press Articles" />
      <section className="wrapper px-0">
        <div className="py-8 md:py-16 space-y-4 md:space-y-8">
          <h1 className="container px-4">Press Articles</h1>
          <div className="bg-gray-100 p-4 md:py-8">
            <div className="container grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 auto-rows-fr gap-4">
              {articles.map(article => (
                <a href={article.link} target="_blank" rel="noreferrer">
                  <Card className="group bg-white shadow-sm h-full transition-shadow duration-500 hover:shadow-xl p-4">
                    <div className="h-16 sm:h-20 mb-4">
                      {article.outletLogo.localFile.extension === "svg" ? (
                        <img
                          src={article.outletLogo.localFile.url}
                          className="object-contain h-full w-full"
                          alt="Publication outlet logo"
                        />
                      ) : (
                        <GatsbyImage
                          // alt={}
                          image={getImage(article.outletLogo.localFile)}
                          className="h-full w-full"
                          objectFit="contain"
                        />
                      )}
                    </div>
                    <div className="3/4 space-y-1">
                      <h4>{article.title}</h4>
                      {/*<p>{article.type}</p>*/}
                      <p>{article.date}</p>
                    </div>
                  </Card>
                </a>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query MyQuery {
    allStrapiPress(sort: { fields: date, order: DESC }) {
      nodes {
        title
        type
        date(formatString: "Do MMMM, YYYY")
        link
        outletLogo {
          localFile {
            extension
            url
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  }
`
